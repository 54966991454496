import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  @Input() image: string;
  @Input() name: string;
  @Input() size = 120;

  constructor() {}

  getPlaceholderAvatarForName(name: string): string {
    if (!name) {
      return `/assets/avatars/0.svg`;
    }

    var num = 0;
    for (var i = 0; i < name.length; i++) {
      num += name.charCodeAt(i);
    }
    return `/assets/avatars/${num % 17}.svg`;
  }

  getImgSource() {
    return this.image || this.getPlaceholderAvatarForName(this.name);
  }
}
